<template>
  <div class="list">
    <ItemList
      v-if="items.count > 0"
      :items="items"
      :fields="fields"
      :options="options"
      @updateDimensions="updateDimensions"
    />
  </div>
</template>

<script>
import { ref, watch, onUpdated } from 'vue'
import axios from 'axios'
import ItemList from '@/components/ItemList'

export default {
  components: {
    ItemList,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateDimensions', 'updateScrollposition'],
  setup(props, { emit }) {
    const loading = ref(false)
    const items = ref({})
    const fields = ref({})

    const getItems = () => {
      loading.value = true
      const apiUrl = `https://api.boatvertizer.com/json?method=ads&lan=${props.options.language}&token=${props.options.token}&sortvalues=1`
      axios.get(apiUrl).then(res => {
        items.value = res.data
        fields.value = res.data.fields
      })
    }

    watch(
      props.options,
      () => {
        if (props.options.token) {
          getItems()
        }
      },
      { immediate: true }
    )

    onUpdated(() => {
      emit('updateDimensions')
      emit('updateScrollposition', 'last')
    })

    const updateDimensions = () => {
      emit('updateDimensions')
    }

    return {
      items,
      fields,
      updateDimensions,
    }
  },
}
</script>

<style lang="scss"></style>
