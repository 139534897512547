<template>
  <div class="header">
    <ul class="filters">
      <li
        v-for="filter in options.filters"
        :key="filter.index"
        class="filters__item"
      >
        <ItemListFilter
          v-if="getFilterValues(filter).length > 0"
          :data="getFilterValues(filter)"
          :name="fields[filter]"
          v-model.trim="selectedFilters[filter]"
        />
      </li>
      <li class="filters__item">
        <ItemListSorting
          v-if="options.sorting.display_options === true"
          :data="options.sorting"
          :name="options.strings.sorting"
          v-model.trim="selectedSorting"
        />
      </li>
    </ul>
    <p class="header__info">
      <span class="header__count">{{ filteredItems.length }}</span> Angebote
      gefunden
    </p>
  </div>
  <div>
    <ul v-if="filteredItems?.length > 0 && options" class="list">
      <li v-for="item in filteredItems" :key="item.id" class="list__item">
        <ItemListEntry :item="item" :fields="fields" :options="options" />
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, watch, onUpdated } from 'vue'
import { cloneDeep } from 'lodash'
import sorter from '@/utils/sorter'
import ItemListFilter from '@/components/ItemListFilter'
import ItemListSorting from '@/components/ItemListSorting'
import ItemListEntry from '@/components/ItemListEntry'

export default {
  components: {
    ItemListFilter,
    ItemListSorting,
    ItemListEntry,
  },
  props: {
    items: {
      type: Object,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateDimensions'],
  setup(props, { emit }) {
    const selectedFilters = ref({})
    const selectedSorting = ref(null)
    const filteredItems = ref({})

    const getFilterValues = name => {
      return [...new Set(props.items.data.map(item => item[name]))]
    }

    // filteredItems.value = list.data

    const updateItems = list => {
      if (!list.count > 0) {
        return
      }

      let newItemList = cloneDeep(list.data)

      for (const [key, value] of Object.entries(selectedFilters.value)) {
        if (value !== '') {
          newItemList = filterItems(newItemList, key, value)
        }
      }

      var selectedSortingObj = props.options.sorting.options.find(obj => {
        return obj.id === selectedSorting.value
      })

      filteredItems.value = sorter(newItemList, selectedSortingObj)
    }

    const filterItems = (list, key, value) => {
      return list.filter(item => item[key] === value)
    }

    watch(
      [selectedFilters.value, selectedSorting, props.items],
      () => {
        if (props.items.count > 0) {
          updateItems(props.items)
        }
      },
      { immediate: true }
    )

    onUpdated(() => {
      emit('updateDimensions')
    })

    return {
      selectedFilters,
      filteredItems,
      updateItems,
      selectedSorting,
      getFilterValues,
    }
  },
}
</script>

<style lang="scss"></style>
