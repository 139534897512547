import { createApp } from 'vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import App from './App.vue'

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(VueSvgInlinePlugin)
  .provide('token', 'f40111d5ddd7faf08b5b63439de4c375')
  .mount('#app')
