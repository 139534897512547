<template>
  <div>
    <router-link :to="{ name: 'ListView' }">
      <span class="navigation">
        <img
          v-svg-inline
          class="icon icon--back"
          src="@/assets/icons/arrow_back.svg"
        />
        {{ options.strings.back_to_overview }}
      </span>
    </router-link>
    <div v-if="item.fotos?.length > 0" class="item__images">
      <swiper
        :modules="modules"
        :slides-per-view="'auto'"
        :space-between="10"
        :centeredSlides="false"
        :lazy="true"
        :navigation="true"
      >
        <swiper-slide v-for="(foto, index) in item.fotos" :key="index">
          <div class="item__image">
            <img
              :src="foto.url"
              :alt="foto.alt"
              class="image"
              @click="openGallery(index)"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="item" class="item__content">
      <div class="item__body">
        <div class="item__header">
          <!-- <span class="item__byline"
        >{{ item.new_or_used }}, {{ item.boat_type }}</span
      > -->
          <h1 class="item__title">{{ item.title }}</h1>
          <div class="item__price price">
            <span class="price__amount">
              {{ item.price }}
            </span>
            <span class="price__info" v-if="item.price_info">
              {{ item.price_info }}
            </span>
          </div>
        </div>
        <div class="item__links">
          <a
            :href="item.pdf"
            class="item__pdf"
            target="_blank"
            v-if="item.pdf?.length > 0"
          >
            <span class="navigation">
              <img
                v-svg-inline
                class="icon icon--pdf"
                src="@/assets/icons/file-pdf.svg"
              />
              {{ options.strings.download_as_pdf }}
            </span>
          </a>
        </div>
        <div class="item__details">
          <div class="item__facts">
            <ul
              class="list"
              v-for="factsGroup in options.facts"
              :key="factsGroup.index"
            >
              <li
                v-for="fact in factsGroup"
                :key="fact.index"
                :data-fact="fact"
              >
                <div v-if="item[fact]" class="list__item">
                  <span class="list__key">
                    {{ fields[fact] }}
                  </span>
                  <span class="list__value">
                    {{ item[fact] }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="item__equipment" v-if="item.equipment?.length > 0">
            <h3 class="item__section-title" v-html="fields.equipment"></h3>
            <ul class="item__equipment-list">
              <li v-for="equipment in item.equipment" :key="equipment.index">
                {{ equipment }}
              </li>
            </ul>
          </div>
          <div
            class="item__description"
            v-if="
              options.descriptions.includes('marketing_descs') &&
              item.marketing_descs?.length > 0
            "
          >
            <h3
              class="item__section-title"
              v-html="fields.marketing_descs"
            ></h3>
            <div v-html="item.marketing_descs"></div>
          </div>
          <div class="item__documents">
            <ul v-if="item.documents?.length > 0" class="item__documents-list">
              <li v-for="document in item.documents" :key="document.index">
                <a :href="document.url" class="item__pdf" target="_blank">
                  <span class="navigation">
                    <img
                      v-svg-inline
                      class="icon icon--pdf"
                      src="@/assets/icons/file-pdf.svg"
                    />
                    {{ document.name }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="item__group" v-if="options.iframes?.length > 0">
            <div v-for="element in options.iframes" :key="element.index">
              <div
                class="item__external-content"
                v-if="item[element] && item[element]?.length > 0"
              >
                <h3 class="item__section-title" v-html="fields[element]"></h3>
                <div class="iframe">
                  <iframe
                    class="iframe__element"
                    :src="item[element]"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

// SWIPER
import { onUpdated } from 'vue'
import { Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/navigation/navigation.scss' // Navigation module
import 'swiper/modules/pagination/pagination.scss' // Pagination module

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    list: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateDimensions', 'showGallery'],
  setup(props, { emit }) {
    const route = useRoute()
    const details = ref({})
    const item = ref({})
    const fields = ref({})
    const gallery = ref({
      items: [],
      active: null,
    })
    const token = inject('token')
    const apiUrl = `https://api.boatvertizer.com/json?method=ads&lan=de&token=${token}&ad=${route.params.id}`

    axios.get(apiUrl).then(res => {
      details.value = res.data
      item.value = res.data.data[0]
      fields.value = res.data.fields

      // Create Gallery
      if (item.value.fotos?.length > 0) {
        // gallery.value.items = item.value.fotos
        Object.values(item.value.fotos).forEach((val, index) => {
          gallery.value.items.push({
            src: val.url,
            w: val.width,
            h: val.height,
            alt: val.alt
              ? `${val.alt} - Photo - ${index}`
              : `${item.value.title} - Photo - ${index}`,
          })
        })
      }
    })

    onUpdated(() => {
      emit('updateDimensions')
    })

    const openGallery = index => {
      gallery.value.active = index
      emit('showGallery', gallery.value)
    }

    return {
      details,
      item,
      fields,
      route,
      modules: [Lazy, Navigation],
      gallery,
      openGallery,
    }
  },
}
</script>

<style lang="scss">
.icon {
  &--accent {
    * {
      fill: var(--colors-accent);
    }
  }
}
</style>
