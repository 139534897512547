<template>
  <router-link :to="{ name: 'DetailView', params: { id: item.id } }">
    <div class="listitem">
      <div class="listitem__images">
        <img :src="imageSize(item.foto, 'small')" alt="" />
      </div>
      <div class="listitem__description">
        <p class="listitem__type">{{ item.boat_type }}</p>
        <h2 class="listitem__title">{{ item.title }}</h2>
        <p class="listitem__subtitle">{{ item.subtitle }}</p>
        <ul class="listitem__features">
          <li
            v-for="fact in options.overview"
            :key="fact.index"
            :data-fact="fact"
          >
            <div v-if="item[fact]" class="listitem__feature">
              <span class="listitem__key">
                {{ fields[fact] }}
              </span>
              <span class="listitem__value">
                {{ item[fact] }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="listitem__side">
        <p class="listitem__price">{{ item.price }}</p>
        <p class="listitem__priceinfo">{{ item.price_info }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
// import { computed } from 'vue'
import imageSize from '@/utils/image-size'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      imageSize,
    }
  },
}
</script>

<style lang="scss"></style>
