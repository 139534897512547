<template>
  <div class="filter">
    <span class="filter__title">{{ name }}</span>
    <select
      name="name"
      id="name"
      @input="updateValue($event.target.value)"
      class="filter__select dropdown"
    >
      <option
        v-for="item in data.options"
        :key="item.index"
        :value="item.id"
        :selected="item.id === data.default"
      >
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script>
// import { computed } from 'vue'

export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const updateValue = value => {
      emit('update:modelValue', value)
    }
    return {
      updateValue,
    }
  },
}
</script>

<style lang="scss"></style>
