export default function (list, sortBy = null) {
  if (!sortBy) {
    return list
  }
  function dynamicSort(property) {
    var sortOrder = 1
    if (property.order === 'desc') {
      sortOrder = -1
    }
    return function (a, b) {
      var result =
        a.sortvalues[property.value] < b.sortvalues[property.value]
          ? -1
          : a.sortvalues[property.value] > b.sortvalues[property.value]
          ? 1
          : 0
      return result * sortOrder
    }
  }

  return list.sort(dynamicSort(sortBy))
}
