export default function (url, size = 'medium') {
  // Available Sizes
  const sizes = [
    'mini',
    'rect',
    'thumbnail',
    'thumbcatalog',
    'panothumb',
    'thumblarge',
    'large',
    'medium',
    'small',
  ]

  if (sizes.includes(size)) {
    sizes.forEach(function (item) {
      url = url.replace(item, size)
    })
  }

  return url
}
