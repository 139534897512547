import { createRouter, createWebHistory } from 'vue-router'

// VIEWS
import ListView from '@/views/ListView.vue'
import DetailView from '@/views/DetailView.vue'

const routes = [
  {
    path: '/',
    redirect: { name: 'ListView' },
  },
  {
    path: '/items',
    name: 'ListView',
    component: ListView,
  },
  {
    path: '/items/:id',
    name: 'DetailView',
    component: DetailView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 })
        }, 500)
      })
    }
  },
})

export default router
