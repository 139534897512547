<template>
  <div class="main" ref="main">
    <div class="container">
      <router-view
        v-if="options.status"
        :options="options"
        @updateDimensions="updateDimensions"
        @updateScrollposition="updateScrollposition"
        @showGallery="triggerGallery"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import Postmate from 'postmate'

export default {
  name: 'App',
  components: {},
  emits: ['updateDimensions', 'openGallery', 'updateScrollPosition'],
  setup() {
    const options = ref({
      token: 'f40111d5ddd7faf08b5b63439de4c375',
      styles: null,
      stylesheet: null,
      language: 'de',
      mode: 'standalone',
    })
    const route = useRoute()
    const main = ref(null)
    const contentHeight = ref()
    const gallery = ref({})
    const showGallery = ref(false)
    const scrollTo = ref(null)
    const currentRoute = ref(null)

    // Postmate.debug = true
    const handshake = new Postmate.Model({
      // Expose your model to the Parent. Property values may be functions, promises, or regular values
      // height: () => document.height || document.body.offsetHeight
    })

    // When parent <-> child handshake is complete, events may be emitted to the parent
    handshake.then(parent => {
      // Get user options
      options.value.token = parent.model.token
        ? parent.model.token
        : options.value.token
      options.value.language = parent.model.language
        ? parent.model.language
        : options.value.language

      options.value.mode = 'iframe'

      getConfig()

      watch(
        [contentHeight, showGallery, scrollTo],
        (
          [contentHeightValue, showGalleryValue, scrollToValue],
          [prevContentHeightValue, prevshowGalleryValue, prevScrollToValue]
        ) => {
          if (contentHeightValue !== prevContentHeightValue) {
            parent.emit('updateHeight', contentHeightValue)
          }
          if (scrollToValue !== prevScrollToValue) {
            parent.emit('updateScrollPosition', scrollToValue)
          }
          if (showGalleryValue && !prevshowGalleryValue) {
            showGallery.value = false
            let data = JSON.parse(JSON.stringify(gallery.value))
            parent.emit('openGallery', data)
          }
        },
        { immediate: true }
      )
    })

    const getConfig = () => {
      const apiUrl = `https://api.boatvertizer.com/json?method=embed&lan=${options.value.language}&token=${options.value.token}`
      axios.get(apiUrl).then(res => {
        options.value = { ...options.value, ...res.data }
        addCustomStyles()
      })
    }

    const updateDimensions = () => {
      if (main.value.clientHeight !== contentHeight.value) {
        contentHeight.value = main.value.clientHeight
      }
    }

    const updateScrollposition = pos => {
      scrollTo.value = pos
    }

    watch(
      [route],
      () => {
        if (route.name !== currentRoute.value) {
          currentRoute.value = route.name
          if (route.name !== 'ListView') {
            scrollTo.value = 'start'
          }
        }
      },
      { immediate: true }
    )

    const triggerGallery = data => {
      gallery.value = data
      showGallery.value = true
    }

    const addCustomStyles = () => {
      if (options.value.stylesheet) {
        var link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        document.head.appendChild(link)
        link.href = options.value.stylesheet
      }
      if (options.value.styles) {
        let fontImport = ''
        let styleString = ':root {'
        for (var key in options.value.styles) {
          for (var item in options.value.styles[key]) {
            if (key === 'font' && item === 'url') {
              fontImport = `@import url('${options.value.styles[key][item]}');\n`
            } else {
              styleString =
                styleString +
                `--${key}-${item}: ${options.value.styles[key][item]};`
            }
            styleString = styleString + '\n'
          }
        }

        styleString = fontImport + styleString + '}'
        const styleTag = document.createElement('style')
        styleTag.setAttribute('type', 'text/css')
        styleTag.appendChild(document.createTextNode(styleString))
        document.head.appendChild(styleTag)
      }
    }

    return {
      main,
      Postmate,
      options,
      updateDimensions,
      updateScrollposition,
      triggerGallery,
    }
  },
}
</script>

<style lang="scss"></style>
